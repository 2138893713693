<template>
  <div class="form--group row" :class="{ 'has-error': validation.hasError('unitNumber') }">
    <div class="col-12 col-lg-3">
      <span>{{ $t('listing.unitNumbering') }}</span>
    </div>
    <div class="col-12 col-lg-6">
      <div class="row my-0">
        <div class="col-6 py-0" style="padding-right: 8px">
          <v-text-field
            :label="$t('listing.placeholder.unitNumber')"
            outlined
            :hide-details="true"
            v-model="unitNumber"
            name="unit_name"
            :class="validation.hasError('unitNumber') ? 'is-invalid' : ''"
            :readonly="readOnly"
          />
          <span class="val-error" v-if="validation.hasError('unitNumber')">{{
            validation.firstError('unitNumber')
          }}</span>
        </div>
        <div class="col-6 py-0" style="padding-left: 8px" v-show="blockNumberRule.allowed">
          <v-text-field
            :label="$t('listing.placeholder.blockNumber')"
            outlined
            :hide-details="true"
            v-model="blockNumber"
            name="block_number"
            :class="validation.hasError('blockNumber') ? 'is-invalid' : ''"
            :readonly="readOnly"
          />
          <span class="val-error" v-if="validation.hasError('blockNumber')">{{
            validation.firstError('blockNumber')
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Validator } from 'simple-vue-validator';
import { mapGetters } from 'vuex';

export default {
  name: 'unit-numbering-section',
  props: ['readOnly'],
  computed: {
    ...mapGetters({
      blockNumberRule: 'listing/form/blockNumberRule',
    }),
    unitNumber: {
      get() {
        return this.$store.state.listing.form.unitNumber;
      },
      set(value) {
        return this.$store.commit('listing/form/SET_UNIT_NUMBER', value);
      },
    },
    blockNumber: {
      get() {
        return this.$store.state.listing.form.blockNumber;
      },
      set(value) {
        return this.$store.commit('listing/form/SET_BLOCK_NUMBER', value);
      },
    },
  },
  methods: {
    validate: function () {
      return this.$validate().then(
        function (success) {
          return !!success;
        }.bind(this),
      );
    },
  },
  validators: {
    blockNumber: function (value) {
      if (!this.blockNumberRule.allowed || !this.blockNumberRule.required) {
        return Validator.value(value).maxLength(
          20,
          this.$i18n.t('errors.addListing.address.max', { maxChar: 20 }),
        );
      } else {
        return Validator.value(value)
          .required(this.$i18n.t('errors.addListing.unit.required'))
          .maxLength(20, this.$i18n.t('errors.addListing.address.max', { maxChar: 20 }));
      }
    },
    unitNumber: function (value) {
      return Validator.value(value).maxLength(
        20,
        this.$i18n.t('errors.addListing.address.max', { maxChar: 20 }),
      );
    },
  },
};
</script>

<style scoped></style>
